<template>
  <BrToolBar @search="buscar" :configFilter="configFilter" :addlFilter="true" :divHidden="true" :labelTextField="labelTextField">
    <BrModalCadastro />
    <v-btn  tile dark outlined> Imprimir </v-btn>
  </BrToolBar>
</template>

<script>
/**
 * MIXIN
 */
import toolbar from '@/views/mixins/toolbar'

export default {
  name: `ToolBarCadastroBonificacoes`,
  mixins: [toolbar],
  components: { 
    BrModalCadastro: () => import('./modalCadastro'),
    BrToolBar: () => import('@/views/components/toolbar')
  }
}
</script>